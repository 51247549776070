<template>
  <b-container fluid>
    <span
      class="views"
      style="color: #006758; background: #e8fff8"
      >Publik</span
    >
    <b-navbar
      toggleable="lg"
      class="navbar-dark bg-darker main-view"
      style="justify-content: space-between; padding: 10px; margin-top: 32.5px"
    >
      <b-navbar-toggle @click="toggleSidebar" aria-label="Öppna/stäng navigation" target="target"></b-navbar-toggle>
      <!--<b-navbar-brand href="#"></b-navbar-brand>-->
      <div></div>
      <!-- Logout Button (only visible when logged in) -->
    </b-navbar>

    <!-- Body: Sidebar and Main Content -->
    <div>
      <!-- Sidebar -->
      <transition name="slide">
        <div class="sidebar bg-darker" v-show="isSidebarVisible">
          <div class="logo">
            <router-link :to="`/`"><img
              src="@/assets/walma.svg"
              alt="Logga för Värmdö"
            /></router-link>
          </div>
          <!-- Sidebar Navigation -->
          <b-nav vertical class="mt-3" aria-label="Sidomeny Navigation">
            <ul class="sidebar-list">
              <li><router-link :to="`/`">
                <i class="bi bi-house" style="margin-right: 10px"></i>Hem
              </router-link></li>
              <li><a href="#" v-b-toggle.collapse-2>
                <i class="bi bi-book" style="margin-right: 10px"></i
                >Delegationsordningar
                <span class="when-opened">
                  <i class="bi bi-chevron-down"></i>
                </span>
                <span class="when-closed">
                  <i class="bi bi-chevron-right"></i>
                </span>
              </a>
              <b-collapse class="submenu" id="collapse-2">
                <router-link
                  v-for="delegation in delegations" :key="delegation.id"
                  :to="`/publik/${delegation.slug}`"
                  @click.native="toggleSidebarIfMobile"
                  >{{ delegation.title
                  }}
                </router-link>
              </b-collapse>
              </li>
              <li><router-link
                :to="`/delegater`"
                @click.native="toggleSidebarIfMobile"
                ><i class="bi bi-people" style="margin-right: 10px"></i
                >Delegater</router-link
              ></li>
              </ul>
            <div class="sidebar-footer" style="display: none">
              <div class="sidebar-footer-item">
                <img src="./assets/corner.png" alt="plus" />
              </div>
            </div>
            <!-- Add more links here -->
          </b-nav>
        </div>
      </transition>

      <!-- Main Content -->
      <b-col lg="10" class="main-view">
        <!-- Your main content goes here -->
        <router-view />
      </b-col>
    </div>
  </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: "App",
  components: {
    //SearchFunction,
  },
  data() {
    return {
      linkTables: [],
      sidebarShow: false, // Controls the visibility of the sidebar
      pendingUpdates: [],
      reportedChanges: [],
      loggedInUserName: null, // Store logged-in user's profile name
      isAdminStatus: false, // Add this line
    };
  },
  created() {
    this.fetchPublicDelegations(); // Anropa Vuex action för att hämta delegationsordningar
  },
  mounted() {
    this.updateSidebarVisibility(); // Existing code
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    toggleSidebar() {
      this.sidebarShow = !this.sidebarShow;
      this.updateSidebarVisibility();
    },
    toggleSidebarIfMobile() {
      if (window.innerWidth <= 992) {
        this.toggleSidebar();
      }
    },
    updateSidebarVisibility() {
      const sidebar = document.querySelector(".sidebar");
      if (this.sidebarShow) {
        sidebar.classList.add("sidebar-show");
      } else {
        sidebar.classList.remove("sidebar-show");
      }
    },
    handleResize() {
      this.sidebarShow = window.innerWidth > 992;
    },
    ...mapActions([
      'fetchPublicDelegations', // Hämta delegationsordningar från din store
    ]),
  },
  computed: {
    isSidebarVisible() {
      // Always show the sidebar on large screens
      return window.innerWidth > 992 || this.sidebarShow;
    },
    ...mapState({
      delegations: state => state.publicDelegations,
    }),
  },
};
</script>

<style>

body {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

* {
  transition: all 0.3s ease-in-out;
}

@media print {
  .no-print {
    visibility: hidden;
  }
  .main {
    width: 100% !important;
    padding: 0px !important;
    margin: 0px !important;
  }
  * {
    font-size: 1pt !important;
  }
  button {
    display: none !important;
  }
}

body {
  background-color: #f6f9fc !important;
}

a {
  color: #342768;
}

h3 {
  color: #342768;
  padding-bottom: 15px;
  font-size: 1.4rem !important;
}

h4 {
  font-size: 1.2rem !important;
}

h5 {
  font-size: 1rem !important;
}

a:hover {
  opacity: 0.6;
}

.sidebar-list a:hover {
  color: rgba(255, 255, 255, 0.5) !important;
  opacity: 1;
}

thead {
  background: #342768 !important;
  color: white !important;
}

.main-view.col-lg-10 {
  width: auto !important;
}

.modal.show .modal-dialog {
  margin-top: 60px;
}

.router-link-exact-active {
  font-weight: bold;
  border-radius: 10px;
}

.sidebar-list .router-link-exact-active {
  background: #342768;
}

.card {
  padding: 15px;
  background: white;
  box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
  border-radius: 10px;
  margin-bottom: 20px;
  border: 0px;
  font-size: 15px;
  border: 0px !important;
}

.btn {
  font-size: 13px !important;
  font-weight: 500 !important;
  border-width: 0px !important;
}

.btn-primary {
  background: #342768 !important;
}

.btn-info {
  background: #e1e5fb !important;
  color: #4458e3 !important;
}

.btn-cancel {
  background: #e1e5fb !important;
  color: #4458e3 !important;
}

.btn-danger {
  background: #fbe1e2 !important;
  color: #e34448 !important;
}

.logo {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 75px;
}

.logo img {
  height: 100%;
  max-width: 200px;
}

.logo a {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.navbar button,
.navbar a {
  color: white !important;
}

/* Fixed Sidebar Styling */
.sidebar {
  position: fixed; /* Fixed positioning */
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100vh; /* Full height */
  overflow-y: auto; /* Scroll for overflow content */
  width: 300px; /* Fixed width */
  transform: translateX(0); /* Initially visible */
  transition: transform 0.3s ease-in-out;
}

/* Adjust main content area */
.main-view {
  margin-left: 300px; /* Adjust margin to account for sidebar width */
  transition: margin-left 0.3s ease-in-out;
}

.sidebar-list {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 15px;
  font-size: 14px;
}

.sidebar-list li {
  padding: 7.5px 0px;
}

.submenu {
  font-size: 14px;
  padding-left: 15px;
}

.submenu a {
  float: left;
}

.sidebar {
  height: 100vh;
}

.sidebar-list a {
  margin: 5px 0px;
  text-decoration: none;
  color: white;
  padding: 5px 10px;
}

.sidebar-footer {
  position: absolute;
  bottom: 0;
}

.sidebar-footer-item img {
  width: 100%;
  margin-bottom: -30px;
}

.bg-darker {
  background-color: #1f2937 !important;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 0px !important;
}

/* Transition for sidebar visibility */
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
}

@media (max-width: 768px) {
  /* Adjust this value based on your breakpoint */
  .sidebar {
    width: 80%; /* Smaller width on smaller screens */
    transform: translateX(-100%); /* Initially off-screen */
    top: 92px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  .main-view {
    margin-left: 0; /* No margin when sidebar is hidden */
  }
  .sidebar-show {
    transform: translateX(0); /* Show sidebar on toggle */
  }
  .logo {
    padding-top: 30px;
  }
}

.hidden {
  display: none;
}

.profile {
  font-size: 14px;
}

.changesBubble {
  font-size: 12px;
  background: #342768;
  color: white;
  border-radius: 50%;
  padding: 3px 6px;
  font-weight: 600;
}

.views {
  display: block;
  width: 100%;
  padding: 5px 5px;
  color: white !important;
  background: #342768 !important;
  font-weight: bold;
  position: fixed;
  z-index: 99999;
  top: 0;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.nav-tabs .nav-link {
  color: #342768 !important;
}

.message {
  position: fixed;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  background: #28a745;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 9999;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, top 0.2s ease-in-out;
}

.is-visible {
  opacity: 1;
  top: 15px;
}

</style>
