<template>
  <div class="card" v-if="loading">
    <b-spinner></b-spinner>
  </div>
  <div v-else>
    <div class="card sidemenu" v-show="showSideMenu">
        <button class="btn btn-primary" @click="showSideMenu = !showSideMenu" style="margin-bottom:15px; width: 90px;">
          <i class="bi bi-x" style="margin-right: 10px"></i>Stäng
        </button>
        <h3>Innehållsförteckning</h3>
        <div v-if="publicDelegationBySlug" class="list-group">
          <h5>
            <a :href="'#' + generateId(publicDelegationBySlug.title)">{{ publicDelegationBySlug.title }}</a>
          </h5>
          <ul>
            <li v-for="(avsnitt, avsnittIndex) in publicDelegationBySlug.rows" :key="avsnitt.id">
              <a v-if="avsnitt.avsnitt !== ''" :href="'#' + generateId(avsnitt.avsnitt)">{{ (avsnittIndex + 1) + ". " + avsnitt.avsnitt }}</a>
              <ul v-if="avsnitt.allTables">
                <div v-for="(table, tableIndex) in avsnitt.allTables" :key="table.key">
                  <li v-if="table.id">
                    <a v-if="table.id" :href="'#' + generateId(table.id)">{{ (avsnittIndex + 1) + "." + (tableIndex + 1) + ". " + table.id }}</a>
                  </li>
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    <b-card no-body>
      <b-tabs card>
        <b-tab tabindex="0" title="Om delegationsordning">
          <div
            style="text-align: left"
            class="om-dele"
            tabindex="0"
            v-if="publicDelegationBySlug && publicDelegationBySlug.about"
            v-html="publicDelegationBySlug.about"
          ></div>
        </b-tab>
        <b-tab tabindex="0" title="Delegationsförteckning" active>
          <div v-if="loading" role="alert" aria-live="assertive">Laddar...</div>
          <div v-else-if="publicDelegationBySlug" tabindex="0">
            <div>
              <h1 :id="generateId(publicDelegationBySlug.title)" tabindex="0">{{ publicDelegationBySlug.title }}</h1>
              <div>
                <button
                  class="btn btn-primary"
                  @click="showSideMenu = !showSideMenu"
                  style="margin: 15px 15px 15px 0px"
                >
                  <i class="bi bi-list-columns" style="margin-right: 10px"></i
                  >Innehållsförteckning
                </button>
                <b-button
                  class="btn btn-primary"
                  @click="handlePdf()"
                  style="margin-right: 15px"
                >
                  <i class="bi bi-file-pdf-fill" style="margin-right: 10px"></i
                  >PDF</b-button
                >
                <b-button style="display: none;" class="btn btn-primary" @click="handlePdfNoVd()">
                  <i class="bi bi-file-pdf-fill" style="margin-right: 10px"></i>PDF utan vidaredelegering</b-button>
              </div>
            </div>
            <div
              v-for="(delegations, avsnittIndex) in publicDelegationBySlug.rows"
              :key="delegations.key"
              class="avsnitt"
            >
              <h2 :id="generateId(delegations.avsnitt)" tabindex="0" v-if="delegations.avsnitt">
                {{ avsnittIndex + 1 + "." }} {{ delegations.avsnitt }}
              </h2>
              <div v-if="delegations.fritext" v-html="delegations.fritext"></div>
              <div
                v-for="(allTables, allTableIndex) in delegations.allTables"
                :key="allTables.key"
                class="table-wrapper"
              >
                <h3 :id="generateId(allTables.id)" tabindex="0" v-if="allTables.id">
                  {{ avsnittIndex + 1 + "." }}{{ allTableIndex + 1 + ". " }}
                  {{ allTables.id }}
                </h3>
                <div v-if="allTables.fritext" v-html="allTables.fritext"></div>
                <table tabindex="0" class="table table-striped table-bordered">
                  <caption v-if="allTables.id" style="display:none;">
                    Tabell
                    {{
                      allTables.id
                    }}
                  </caption>
                  <caption v-else-if="delegations.avsnitt" style="display:none;">
                    Tabell
                    {{
                      delegations.avsnitt
                    }}
                  </caption>
                  <thead style="background: #342768; color: white">
                    <tr>
                      <th tabindex="0" scope="col" style="width: 5%">Nr</th>
                      <th tabindex="0" scope="col" style="width: 20%">
                        Ärendetyp
                      </th>
                      <th tabindex="0" scope="col" style="width: 10%">
                        Delegat
                      </th>
                      <th tabindex="0" scope="col" style="width: 6%">
                        Vidaredelegation
                      </th>
                      <th tabindex="0" scope="col" style="width: 6%">Lagrum</th>
                      <th tabindex="0" scope="col" style="width: 19%">
                        Anmärkning
                      </th>
                      <th tabindex="0" scope="col" style="width: 35%">
                        Ärenden som vidaredelegeras / Vidaredelegat
                      </th>
                      <!--<th tabindex="0" scope="col" style="width: 10%">
                        Vidaredelegat
                      </th>-->
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(row, rowIndex) in allTables.table"
                      :key="row.key"
                    >
                      <td tabindex="0">
                        {{ avsnittIndex + 1 + "." }}{{ allTableIndex + 1 + "."
                        }}{{ rowIndex + 1 }}
                      </td>
                      <td tabindex="0">{{ row.arendetyp }}</td>
                      <td tabindex="0">
                        <span
                          v-b-modal.modal-5
                          @click="matchDelegat(row.delegat)"
                          class="clickable"
                        >
                          {{ row.delegat }}
                        </span>
                      </td>
                      <td tabindex="0">{{ row.vd }}</td>
                      <td tabindex="0">{{ row.lagrum }}</td>
                      <td tabindex="0">{{ row.anmarkning }}</td>
                      <td tabindex="0" style="height: 100px; padding:0px;">
                        <table class="vidareTable">
                          <tbody>
                            <tr v-for="(arende, arendeIndex) in row.arendenVidare" :key="arende.key">
                              <td tabindex="0"> {{ arende }} </td>
                              <td tabindex="0"><span v-b-modal.modal-5 @click="matchDelegat(row.vidaredelegat[arendeIndex])" class="clickable">{{ row.vidaredelegat[arendeIndex] }}</span></td>
                            </tr>
                          </tbody>
                        </table>
                        <!--<span
                          tabindex="0"
                          v-for="arende in row.arendenVidare"
                          :key="arende.key"
                          :class="[getClassBasedOnLength(row.arendenVidare), { 'arendenArray': row.arendenVidare.length > 1 }]"
                        >
                          {{ arende }}
                        </span>-->
                      </td>
                      <!--<td tabindex="0">
                        <span
                          tabindex="0"
                          v-b-modal.modal-5
                          v-for="delegat in row.vidaredelegat"
                          :key="delegat.key"
                          @click="matchDelegat(delegat)"
                          class="clickable"
                          :class="[getClassBasedOnLength(row.arendenVidare), { 'arendenArray': row.arendenVidare.length > 1 }]"
                        >
                          {{ delegat }}
                        </span>
                      </td>-->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab tabindex="0" title="Bilaga">
          <div
            style="text-align: left"
            class="om-dele"
            tabindex="0"
            v-if="publicDelegationBySlug && publicDelegationBySlug.bilaga"
            v-html="publicDelegationBySlug.bilaga"
          ></div>
          <div v-else>
            Delegationsordningen har ingen bilaga.
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-modal
      id="modal-5"
      size="xl"
      :title="this?.currentDelegat"
      ref="showDelegat-modal"
      hide-footer
      hide-close
    >
    <b-tabs>
      <b-tab title="Delegat" v-if="this.matchDelegations != ''">
        <h6 style="padding-top: 15px;">{{ this.currentDelegat }} är delegat i följande ärenden:</h6>
        <table class="table table-striped table-bordered">
          <thead style="background: #ff8204; color: white">
            <tr>
              <th>Delegationsordning</th>
              <th>Avsnitt</th>
              <th>Tabell</th>
              <th>Nr</th>
              <th>Typ av ärenden</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="matched in this.matchDelegations" :key="matched.key">
              <td>{{ matched.delegationsordning }}</td>
              <td @click="closeModal()">
                <router-link :to="'/publik/' + matched.slug">{{
                  matched.avsnitt
                }}</router-link>
              </td>
              <td>{{ matched.tabell }}</td>
              <td>{{ matched.nr }}</td>
              <td>{{ matched.arendetyp }}</td>
            </tr>
          </tbody>
        </table>
      </b-tab>
      <b-tab title="Vidaredelegat" v-if="this.matchVd != ''">
        <h6 style="padding-top: 15px;">{{ this.currentDelegat }} är vidaredelegat i följande ärenden:</h6>
        <table class="table table-striped table-bordered">
          <thead style="background: #ff8204; color: white">
            <tr>
              <th>Delegationsordning</th>
              <th>Avsnitt</th>
              <th>Tabell</th>
              <th>Nr</th>
              <th>Ärenden som vidaredelegeras</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="matched in this.matchVd" :key="matched.key">
              <td>{{ matched.delegationsordning }}</td>
              <td>
                <router-link :to="'/publik/' + matched.slug">{{
                  matched.avsnitt
                }}</router-link>
              </td>
              <td>{{ matched.tabell }}</td>
              <td>{{ matched.nr }}</td>
              <td>
                          <ul v-if="matched.arendenVidare.length > 1">
                            <li v-for="arende in matched.arendenVidare" :key="arende">{{ arende }}</li>
                          </ul>
                          <div v-else>
                             <div v-for="arende in matched.arendenVidare" :key="arende">
                              {{ arende }}
                            </div>
                          </div>
                        </td>
            </tr>
          </tbody>
        </table>
      </b-tab>
      <div v-if="this.matchVd == '' && this.matchDelegations == ''">
        <h6>{{ this.currentDelegat }} får inte fatta delegationsbesut</h6>
      </div>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import { generatePdf, generatePdfNoVd } from "./pdfgenerator.js";

export default {
  data() {
    return {
      loading: true,
      parsedData: [],
      matchDelegations: [],
      matchVd: [],
      currentDelegat: '',
      slug: '',
      showSideMenu: false,
    };
  },
  computed: {
    publicDelegations() {
      return this.$store.state.publicDelegations;
    },
    publicDelegationBySlug() {
      return this.$store.state.publicDelegationBySlug;
    },
    generateId() {
      return (title) => {
        return title?.toLowerCase().replace(/[^a-z0-9]+/g, "-");
      };
    },
  },
  methods: {
    resetData() {
      this.parsedData = [];
      this.matchDelegations = [];
      this.matchVd = [];
      this.currentDelegat = [];
      // Rensa andra datan som behövs
    },
    handlePdf() {
      // Först, spara nuvarande data om det behövs
      const originalData = JSON.parse(JSON.stringify(this.publicDelegationBySlug));

      // Generera PDF
      this.parseJsonData(this.publicDelegationBySlug);
      generatePdf(this.publicDelegationBySlug.about, this.parsedData, this.publicDelegationBySlug.bilaga);

      // Återställ data (antingen med sparad kopia eller genom att ladda om)
      this.publicDelegationBySlug = originalData;
      // Eller ladda om data från källan
      this.reloadData();
    },
    handlePdfNoVd() {
      const originalData = JSON.parse(JSON.stringify(this.publicDelegationBySlug));

// Generera PDF
      this.parseJsonData(this.publicDelegationBySlug);
      generatePdfNoVd(this.publicDelegationBySlug.about, this.parsedData, this.publicDelegationBySlug.bilaga);
            // Återställ data (antingen med sparad kopia eller genom att ladda om)
      this.publicDelegationBySlug = originalData;
      // Eller ladda om data från källan
      this.reloadData();
    },
    matchDelegat(delegatTitel) {
      this.currentDelegat = delegatTitel;

      const delegations = this.$store.state.publicDelegations;

      // Töm eventuella tidigare resultat
      // Töm eventuella tidigare resultat
      this.matchVd = [];
      this.matchDelegations = [];

      delegations.forEach((delegation) => {
        delegation.rows?.forEach((avsnitt) => {
          avsnitt.allTables?.forEach((allTable) => {
            allTable.table?.forEach((table) => {
              // Hitta index för alla vidaredelegat som matchar user.titel
              const vidaredelegatIndexes = table.vidaredelegat?.reduce((acc, delegat, index) => {
                if (delegat === delegatTitel) {
                  acc.push(index);
                }
                return acc;
              }, []);

              // Använd de hittade indexen för att få motsvarande arendenVidare
              if (vidaredelegatIndexes.length > 0) {
                const arendenVidareMatches = vidaredelegatIndexes.map(index => table.arendenVidare[index]);

                const delegationMatch = {
                  id: delegation.id,
                  delegationsordning: delegation?.title,
                  avsnitt: avsnitt.avsnitt,
                  tabell: allTable.id,
                  nr: table.nr,
                  arendenVidare: arendenVidareMatches, // Spara som en array
                  slug: delegation.slug
                };
                this.matchVd.push(delegationMatch);
              }

              // Matchning för delegat
              if (table.delegat === delegatTitel) {
                const delegationMatch = {
                  id: delegation.id,
                  delegationsordning: delegation?.title,
                  avsnitt: avsnitt.avsnitt,
                  tabell: allTable.id,
                  nr: table.nr,
                  arendetyp: table.arendetyp,
                  slug: delegation.slug
                };
                this.matchDelegations.push(delegationMatch);
              }
            });
          });
        });
      });
      this.getData();
    },
    parseJsonData(jsonData) {
      try {
        this.parsedData.push(jsonData);
      } catch (e) {
        console.error("Error parsing JSON data", e);
        this.parsedData = null;
      }
    },
    getData() {
      if (this.$store.state.publicDelegations.length === 0) {
        this.loading = true; // Aktivera laddningsskärmen
        this.$store.dispatch("fetchPublicDelegations").finally(() => {
          this.loading = false; // Stäng av laddningsskärmen när operationen är klar
        });
      }
    },
    reloadData() {
      this.loading = true; // Aktivera laddningsskärmen
      this.$store.dispatch("fetchPublicDelegations").finally(() => {
        this.loading = false; // Stäng av laddningsskärmen när operationen är klar
      });
    },
    loadDelegationBySlug(slug) {
      this.loading = true; // Aktivera laddningsskärmen
      this.$store.dispatch('fetchDelegationBySlug', slug)
        .then(() => {
          this.loading = false; // Stäng av laddningsskärmen när datan är färdigladdad
        })
        .catch(error => {
          console.error('Error loading delegation:', error);
          this.loading = false; // Stäng av laddningsskärmen även vid fel
        });
    },
    getClassBasedOnLength(text) {
      if (text[0]?.length > 300) {
        return 'long-text';
      } else if (text[0]?.length > 150) {
        return 'medium-text';
      } else {
        return 'short-text';
      }
    },
  },
  watch: {
    '$route.params.slug'(newSlug) {
      this.loadDelegationBySlug(newSlug);
      this.resetData();
    }
  },
  mounted() {
    this.slug = this.$route.params.slug;
    this.loadDelegationBySlug(this.slug);
  },
};
</script>

<style scoped>
caption {
  color: #050505;
}

h1 {
  font-size: 1.8rem!important;
}

h2 {
  font-size: 1.6rem!important;
}

td {
  font-size: 0.85rem;
}

.clickable {
  text-decoration: underline;
}
.arendenArray {
  display: block;
  padding: 10px 0px;
  border-bottom: 1px solid #ccc;
  min-height: 250px;
}

.arendenArray:first-child { 
  padding: 0px 0px 10px 0px;
}

.arendenArray:last-child { 
  border-bottom: 0px;
}

/deep/ .om-dele table,
.om-dele td {
  border: 1px solid #e3e3e3;
}

/deep/ .om-dele h2 {
  font-size: 1.6rem!important;
}

/deep/ .om-dele td {
  padding: 5px;
  border-width: 1px!important;
}

/deep/ .om-dele p {
  margin-bottom: 7.5px;
}

.avsnitt {
    margin: 15px 0px;
    float: left;
    width: 100%;
}

.avsnitt .table-wrapper{
  overflow: scroll;
}

table td a {
    color: black;
}

.sidemenu {
  position: fixed;
  top: 35px;
  right: 0;
  height: 100vh;
  background-color: white;
  padding: 20px;
  overflow-y: scroll;
  z-index: 999;
  width: 40%;
}

.sidemenu ul {
  list-style-type: none;
}

.sidemenu a {
  color: #342768;
  text-decoration: none;
}

.long-text {
  min-height: 400px;
}
.medium-text {
  min-height: 250px;
}
.short-text {
  min-height: 160px;
}

.vidareTable td {
    width: 50%!important;
    padding:10px;
}

table.vidareTable tr {
    width: 100%!important;
    padding:10px 0px;
    border-top:1px solid #d1d5d9;
}

table.vidareTable tr:first-child {
    border:none;
}



table.vidareTable { border: none!important; border-collapse: collapse; }
.vidareTable  td { border-left: 1px solid #d1d5d9!important; }
.vidareTable  td:first-child { border-left: none!important; }

.vidareTable {
  width: 100%; /* Fyller bredden av den yttre <td> */
  height: 100%; /* Fyller höjden av den yttre <td> */
}

.vidareTable td {
  height: 100%; /* Får varje cell i den inre tabellen att expandera vertikalt */
  vertical-align: top;
}

</style>